import React, { useState, useEffect } from "react";

// lib
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { GiReceiveMoney } from "react-icons/gi";

// custom
import { useAuthContext } from "../../../contexts/auth_context";
import { axios_auth } from "../../../utils/axios";
import { api_url } from "../../../utils/constants";
import ChartComponnet from "./ChartComponnet";
import { FaAward, FaCheckCircle } from "react-icons/fa";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import UserTable from "./UserTable";
import { classNames } from "../../../utils/helpers";

function AppDetails() {
	const { logout, user } = useAuthContext();
	const [homeDetails, setHomeDetails]: any = useState([]);
	const [userType, setUserType] = useState(1);

	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.get(api_url + "/admin/stats")
			.then((response: any) => {
				//console.log("key", response.data.data);
				setHomeDetails(response.data.data);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, []);

	return (
		<div className="flex flex-col mx-auto">
			{/* cards */}
			<div className="flex flex-wrap items-center justify-center">
				<div className="flex flex-col text-center w-[300px] h-[150px] mx-5 my-3 items-center justify-center space-y-4 border border-clr-secondary-bright px-20 py-5 rounded-3xl bg-gradient-to-r from-clr-secondary-dark to-clr-secondary-bright">
					<span className="text-clr-off-white text-xl font-bold">
						Total Users
					</span>
					<span className="text-clr-main-dark font-bold text-lg">
						{homeDetails?.totalUsers ? homeDetails?.totalUsers : 0}
					</span>
				</div>
				<div className="flex flex-col text-center w-[300px] h-[150px] mx-5 my-3 items-center justify-center space-y-4 border border-clr-secondary-bright px-20 py-5 rounded-3xl bg-gradient-to-r from-clr-secondary-dark to-clr-secondary-bright">
					<span className="text-clr-off-white text-xl font-bold">
						Subscribed Users
					</span>
					<span className="text-clr-main-dark font-bold text-lg">
						{homeDetails?.stakedUser ? homeDetails?.stakedUser : 0}
					</span>
				</div>
				<div className="flex flex-col text-center w-[300px] h-[150px] mx-5 my-3 items-center justify-center space-y-4 border border-clr-secondary-bright px-20 py-5 rounded-3xl bg-gradient-to-r from-clr-secondary-dark to-clr-secondary-bright">
					<span className="text-clr-off-white text-xl font-bold">
						Active Users
					</span>
					<span className="text-clr-main-dark font-bold text-lg">
						{homeDetails?.totalActiveUserPerDay
							? homeDetails?.totalActiveUserPerDay
							: 0}
					</span>
				</div>
			</div>
			{/* CHART && BASE INESTMENT */}
			<div className="flex S-1370:flex-row flex-col items-start S-1370:space-x-8 w-full">
				<div className="relative S-1370:w-1/3 md:w-[60%] w-8/10 S-1370:mx-0 mx-auto">
					<div
						id="borderTest"
						className=" flex flex-col space-y-4 items-start justify-center pl-8 pr-10 py-10 mt-10 z-30 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg"
					>
						<div className="flex flex-col items-start text-xl font-semibold space-y-3 border-b border-clr-secondary-bright pb-5 w-full">
							<span className="text-clr-off-white flex space-x-2 items-center">
								<GiReceiveMoney className="w-6 h-6" />
								<span>Base Investment</span>
							</span>
							<span className="ml-2 text-clr-main-bright">
								{homeDetails?.totalInvestment
									? parseFloat(homeDetails?.totalInvestment).toFixed(4)
									: 0}{" "}
								ToL
							</span>
							<p className="text-lg">
								<span>Fixed : </span>
								<span className="ml-2 text-clr-main-bright">
									{homeDetails?.totalFixedStaking
										? parseFloat(homeDetails?.totalFixedStaking).toFixed(4)
										: 0}{" "}
									ToL
								</span>
							</p>
							<p className="text-lg">
								<span>Flexi : </span>
								<span className="ml-2 text-clr-main-bright">
									{homeDetails?.totalFlexiStaking
										? parseFloat(homeDetails?.totalFlexiStaking).toFixed(4)
										: 0}{" "}
									ToL
								</span>
							</p>
						</div>

						<div className="flex flex-col items-start text-xl font-semibold space-y-3">
							<span className="text-clr-off-white flex space-x-2 items-center">
								<FaAward className="w-6 h-6" />
								<span>Total Rewards</span>
							</span>
							<span className="ml-2 text-clr-main-bright">
								{homeDetails?.rootRewards
									? parseFloat(homeDetails?.rootRewards.total).toFixed(4)
									: 0}{" "}
								ToL
							</span>
							<ul className="ml-5 text-base list-disc">
								<li className="flex flex-row items-center justify-between space-x-2 text-clr-secondary-bright">
									<span className="">Staking Rewards :</span>
									<span className="text-clr-off-white">
										{homeDetails?.rootRewards
											? parseFloat(homeDetails?.rootRewards.self).toFixed(4)
											: 0}
									</span>
								</li>
								<li className="flex flex-row items-center justify-between text-clr-secondary-bright">
									<span className="">Direct Rewards :</span>
									<span className="text-clr-off-white">
										{homeDetails?.rootRewards
											? parseFloat(homeDetails?.rootRewards.direct).toFixed(4)
											: 0}
									</span>
								</li>
								<li className="flex flex-row items-center justify-between text-clr-secondary-bright">
									<span className="">Team Rewards :</span>
									<span className="text-clr-off-white">
										{homeDetails?.rootRewards
											? parseFloat(homeDetails?.rootRewards.team).toFixed(4)
											: 0}
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<ChartComponnet data={homeDetails?.packageDetails} />
			</div>
			{/* ACTIVE USER */}
			<div className="flex flex-col space-y-3 justify-center">
				<h1 className="flex flex-row items-center space-x-2 text-start text-4xl text-clr-main-bright font-semibold py-5">
					<AiOutlineUsergroupAdd />
					<span>Users :</span>
				</h1>
				<ul className="flex flex-row space-x-5 ml-4 text-lg font-semibold">
					<li
						className={classNames(
							"flex flex-row items-center space-x-2 cursor-pointer",
							userType == 1
								? "text-clr-secondary-bright border-b border-clr-secondary-bright"
								: "text-clr-off-white"
						)}
						onClick={() => {
							setUserType(1);
						}}
					>
						{userType == 1 && <FaCheckCircle />}
						<span>Active</span>
					</li>
					<li
						className={classNames(
							"flex flex-row items-center space-x-2 cursor-pointer",
							userType == 2
								? "text-clr-secondary-bright border-b border-clr-secondary-bright"
								: "text-clr-off-white"
						)}
						onClick={() => {
							setUserType(2);
						}}
					>
						{userType == 2 && <FaCheckCircle />}
						<span>In-Active</span>
					</li>
				</ul>
				<UserTable type={userType} />
			</div>
		</div>
	);
}

export default AppDetails;
