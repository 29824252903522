import React from "react";

// lib
import { Link, useLocation } from "react-router-dom";

// custom
import { classNames } from "../../utils/helpers";
import { useAuthContext } from "../../contexts/auth_context";

function Aside() {
	const { logout, user } = useAuthContext();
	let location = useLocation();

	return (
		<div className="S-950:col-span-3 col-span-12 S-950:py-6 py-1 px-4 border border-clr-secondary-bright rounded-lg S-950:h-[300px] h-[60px]">
			<ul
				id="sideScroll"
				className="flex S-950:flex-col flex-row S-950:space-x-0 space-x-5 S-950:min-w-fit S-950:overflow-x-hidden overflow-x-scroll h-full"
			>
				<li
					className={classNames(
						"py-2 sm:border-b border-clr-secondary-bright pl-2 hover:text-clr-main-bright",
						location.pathname == "/user/dashboard"
							? "text-clr-main-bright"
							: "text-clr-off-white"
					)}
				>
					<Link className="" to={"/user/dashboard"}>
						Dashboard
					</Link>
				</li>
				{/* <li
					className={classNames(
						"py-2 sm:border-b border-clr-secondary-bright pl-2 hover:text-clr-main-bright",
						location.pathname == "/user/staking"
							? "text-clr-main-bright"
							: "text-clr-off-white"
					)}
				>
					<Link className="" to={"/user/staking"}>
						Staking
					</Link>
				</li> */}
				{/* <li
					className={classNames(
						"py-2 sm:border-b border-clr-secondary-bright pl-2 hover:text-clr-main-bright",
						location.pathname == "/user/wallet"
							? "text-clr-main-bright"
							: "text-clr-off-white"
					)}
				>
					<Link className="" to={"/user/wallet"}>
						My Wallet
					</Link>
				</li> */}
				{/* <li
					className={classNames(
						"py-2 sm:border-b border-clr-secondary-bright pl-2 hover:text-clr-main-bright",
						location.pathname == "/user/statistics"
							? "text-clr-main-bright"
							: "text-clr-off-white"
					)}
				>
					<Link className="" to={"/user/statistics"}>
						My Statistic
					</Link>
				</li> */}
				<li
					className={classNames(
						"py-2 sm:border-b border-clr-secondary-bright pl-2 hover:text-clr-main-bright",
						location.pathname == "/user/team"
							? "text-clr-main-bright"
							: "text-clr-off-white"
					)}
				>
					<Link className="" to={"/user/team"}>
						Team
					</Link>
				</li>
				<li
					className={classNames(
						"py-2 sm:border-b border-clr-secondary-bright pl-2 hover:text-clr-main-bright",
						location.pathname == "/user/withdrawals"
							? "text-clr-main-bright"
							: "text-clr-off-white"
					)}
				>
					<Link className="" to={"/user/withdrawals"}>
						Withdrawals
					</Link>
				</li>
				<li
					className={classNames(
						"py-2 sm:border-b border-clr-secondary-bright pl-2 hover:text-clr-main-bright",
						location.pathname == "/user/rewards"
							? "text-clr-main-bright"
							: "text-clr-off-white"
					)}
				>
					<Link className="" to={"/user/rewards"}>
						Rewards
					</Link>
				</li>
				<li className="py-2 sm:border-b border-clr-secondary-bright last:border-none pl-2 hover:text-clr-main-bright ">
					<button className="" onClick={logout}>
						Logout
					</button>
				</li>
			</ul>
		</div>
	);
}

export default Aside;
