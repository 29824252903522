import React, { useState, useEffect } from "react";

// lib
import { Button, Modal } from "antd";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// custom
import PlansList from "../../components/Client/Plans/PlansList";
import { useMainContext } from "../../contexts/main_context";
import { classNames } from "../../utils/helpers";
import { useAuthContext } from "../../contexts/auth_context";
import { axios_auth } from "../../utils/axios";
import { api_url } from "../../utils/constants";

const key_REGEX = /^[a-zA-Z0-9]{64,66}$/;

function PlansPage() {
	const { logout, user } = useAuthContext();
	const { selectedPlan, setSelectedPlan } = useMainContext();
	const [open, setOpen] = useState(false);
	const [pKey, setPKey] = useState("");
	const [pKeyValid, setPKeyValid] = useState(false);
	const [pKeyFocus, setPKeyfocus] = useState(false);
	const [loading, setLoading] = useState(false);

	//set private key
	const handlePassword = (e: any) => {
		const val = e.currentTarget.value;
		setPKey(val);
		const data = {
			package_id: selectedPlan.package_id,
			base_investment: selectedPlan.base_investment,
			type: selectedPlan.type,
			privateKey: val,
		};
		setSelectedPlan(data);
	};
	//check the validation for private key
	useEffect(() => {
		setPKeyValid(key_REGEX.test(pKey));
	}, [pKey]);
	// open modal
	const showModal = () => {
		setOpen(true);
	};
	// set OK
	const handleOk = () => {
		setTimeout(() => {
			setOpen(false);
		}, 2000);
	};
	// set cancel
	const handleCancel = () => {
		console.log("Clicked cancel button");
		setOpen(false);
	};
	// set plan type
	const handlePlanType = (e: any) => {
		const data = {
			package_id: selectedPlan.package_id,
			base_investment: selectedPlan.base_investment,
			type: e.target.id,
			privateKey: selectedPlan.privateKey,
		};
		setSelectedPlan(data);
	};
	// set amount
	const handleInput = (e: any) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		const data = {
			package_id: selectedPlan.package_id,
			base_investment: value,
			type: selectedPlan.type,
			privateKey: selectedPlan.privateKey,
		};
		setSelectedPlan(data);
	};

	// testing
	// const handleSibscribe = async () => {
	// 	console.log("plan", selectedPlan);
	// 	showModal();
	// };

	// submit
	const handleSendTransaction = () => {
		// const buff = Buffer.from(pKey, "utf8");
		// const encryptedKey = buff.toString("base64");
		console.log("key", pKey, selectedPlan);
		setLoading(true);
		axios_auth(Cookies.get("token"))
			.post(api_url + "/make-subscription", selectedPlan)
			.then((response: any) => {
				if (response.data.status) {
					toast.success("", {
						position: "top-left",
					});
					handleCancel();
					setPKey("");
				} else {
					toast.error(response.data.message, {
						position: "top-left",
					});
				}
				setLoading(false);
				console.log("data", response.data.data);
			})
			.catch((error) => {
				console.log("data", error);
				setLoading(false);
				toast.error(error.message, {
					position: "top-left",
				});
			});
	};

	return (
		<div className="h-auto relative overflow-hidden flex lg:items-center items-start lg:justify-center justify-start">
			{/* bolb */}
			<div
				id="bolb"
				className="absolute w-[300px] h-[300px] S-xl:-top-[5px] lg:top-1 -top-25 right-[100px] rounded-full"
			></div>
			{/* end bolb */}
			<div className="flex flex-col relative top-14 mb-20 mx-auto">
				<h1 className="w-full text-clr-main-bright font-bold text-5xl text-center">
					Our Plans
				</h1>
				<PlansList />
				{/* input */}
				<div
					className={classNames(
						"flex flex-col border border-clr-secondary-bright rounded md:w-1/2 w-full mx-auto relative py-2 mb-10"
					)}
				>
					<label
						htmlFor="email"
						className="font-bold text-xl absolute -top-4 left-5 bg-clr-background rounded px-3"
					>
						Base Investment (ToL)
					</label>
					<input
						type="text"
						id="baseInvest"
						name="baseInvest"
						value={selectedPlan.base_investment}
						onChange={handleInput}
						autoComplete="off"
						// aria-invalid={formValidate.email ? "false" : "true"}
						// aria-describedby="uidnote"
						// onFocus={() => setFormFocus({ ...formFocus, email: true })}
						// onBlur={() => setFormFocus({ ...formFocus, email: false })}
						className="bg-transparent py-3 px-6 outline-none text-lg placeholder:text-clr-off-white placeholder:text-base placeholder:font-normal"
						placeholder="Base Investment"
					/>
					{/* <div
									id="uidnote"
									className={
										formFocus.email && formData.email && !formValidate.email
											? " font-medium absolute -bottom-4 text-red-500 text-xs ml-4 mt-1 "
											: "hidden"
									}
								>
									Email not valid
								</div> */}
				</div>
				<div className="flex flex-row items-center justify-center space-x-2">
					<span
						className={classNames(
							selectedPlan.type == "fixed"
								? "text-clr-main-dark bg-clr-main-bright"
								: "text-clr-off-white bg-transparent",
							"rounded-full border border-clr-main-bright px-5 py-1 font-semibold cursor-pointer"
						)}
						onClick={(e) => handlePlanType(e)}
						id="fixed"
					>
						Fixed
					</span>
					<span
						className={classNames(
							selectedPlan.type == "flexi"
								? "text-clr-main-dark bg-clr-main-bright"
								: "text-clr-off-white bg-transparent",
							"rounded-full border border-clr-main-bright px-5 py-1 font-semibold cursor-pointer"
						)}
						onClick={(e) => handlePlanType(e)}
						id="flexi"
					>
						Flexi
					</span>
				</div>
				<div className="flex flex-col items-center justify-center mt-16  w-6/12 mx-auto">
					<button
						type="button"
						onClick={showModal}
						className="rounded-lg w-full text-clr-main-dark hover:text-clr-main-bright bg-clr-main-bright border border-clr-main-bright hover:bg-transparent py-2 text-xl font-bold"
					>
						Subscribe
					</button>
				</div>
			</div>
			{/* modal */}
			<Modal
				title={
					<div className="flex flex-row space-x-1 items-center">
						{/* <GiConfirmed className='text-clr-blue-focus w-8 h-8' /> */}
						<h1 className="text-xl font-bold text-clr-main-dark">
							Transction Process
						</h1>
					</div>
				}
				style={{ top: 20 }}
				footer={<div></div>}
				open={open}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<div className="w-full flex flex-col space-y-6 items-center justify-center mt-5">
					<div className="flex flex-col items-center justify-center">
						<h3 className="w-full mx-auto text-center text-xl font-medium text-clr-secondary-bright">
							Please put your private key to complete..
						</h3>
					</div>
					<div className={"flex flex-col text-base py-2 px-2 space-y-5 w-full"}>
						{/* amount   */}
						<div className="flex flex-col space-y-1">
							<span className="text-clr-main-dark text-sm font-medium">
								Amount
							</span>
							<span className="border border-clr-main-dark px-4 py-2 rounded-full w-full font-bold shadow text-base">
								{selectedPlan.base_investment}
							</span>
						</div>
						{/* public key  */}
						<div className="flex flex-col space-y-1">
							<span className="text-clr-main-dark text-sm font-medium">
								Your Address
							</span>
							<span className="border border-clr-main-dark px-4 py-2 rounded-full font-bold shadow text-base">
								{user.user.wallet_address}
							</span>
						</div>
						{/* private */}
						<div className="flex flex-col space-y-2">
							<label
								htmlFor="p_key"
								className="text-clr-main-dark text-sm font-medium"
							>
								Private Key
							</label>
							<input
								id="p_key"
								name="p_key"
								type={"password"}
								autoComplete="off"
								aria-invalid={pKeyValid ? "false" : "true"}
								aria-describedby="uidnote"
								onFocus={() => setPKeyfocus(true)}
								onBlur={() => setPKeyfocus(false)}
								value={pKey}
								onChange={(e) => handlePassword(e)}
								className={classNames(
									"px-4 py-2 rounded-full font-bold text-clr-gray-dark text-base outline-none",
									pKeyValid ? "shadow shadow-green-400" : "shadow border",
									!pKeyValid && pKeyFocus
										? "shadow shadow-red-400"
										: "shadow-input"
								)}
							/>
						</div>
					</div>
					<div className="flex flex-col items-center justify-center">
						<button
							disabled={!pKeyValid}
							className={classNames(
								!pKeyValid ? "opacity-50" : "",
								"py-1 px-4 text-clr-main-dark font-semibold bg-clr-main-bright disabled:bg-clr-secondary-bright rounded-lg text-lg hover:bg-clr-secondary-dark hover:text-clr-off-white cursor-pointer disabled:cursor-default"
							)}
							onClick={handleSendTransaction}
						>
							Pay
							{loading && (
								<svg
									aria-hidden="true"
									role="status"
									className="inline w-4 h-4 ml-3 text-clr-main-dark font-semibold animate-spin"
									viewBox="0 0 100 101"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
										fill="#E5E7EB"
									/>
									<path
										d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
										fill="currentColor"
									/>
								</svg>
							)}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

export default PlansPage;
