import React from "react";

//custom
import Aside from "../../components/Shared/Aside";
import { useAuthContext } from "../../contexts/auth_context";
import WithdrawalsContent from "../../components/Client/Withdrawals/WithdrawalsContent";

function WithdrawalsPages() {
	const { logout, user } = useAuthContext();
	return (
		<div className="my-10 h-auto  relative flex lg:items-center items-start lg:justify-center justify-start">
			{/* bolb */}
			<div
				id="bolb"
				className="absolute w-[300px] h-[300px] S-xl:-top-[5px] lg:top-1 -top-25 right-[100px] rounded-full"
			></div>
			{/* end bolb */}
			<div className="mx-auto lg:my-0 my-20 lg:max-w-max-custom max-w-max-width w-90vw">
				<div className="grid grid-cols-12 gap-5">
					{/* aside */}
					<Aside />
					{/* content */}
					<div className="S-950:col-span-9 col-span-12">
						<div className="flex flex-col space-y-6">
							<div className="py-4 border-b border-clr-main-bright flex lg:flex-row lg:space-y-0 flex-col space-y-1 lg:items-center items-start justify-between">
								<p>
									<span>Name: </span> <span>{user.user.name}</span>
								</p>
								<p>
									<span>Email: </span> <span>{user.user.email}</span>
								</p>
								<p>
									<span>Referral code: </span>{" "}
									<span>{user.user.referral_code}</span>
								</p>
							</div>
							<WithdrawalsContent />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WithdrawalsPages;
