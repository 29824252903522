import React, { useState, useEffect } from "react";

// lib
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { Pagination } from "antd";
import ExcelJS from "exceljs";
import { format } from "date-fns";

// custom
import { useAuthContext } from "../../../contexts/auth_context";
import { axios_auth } from "../../../utils/axios";
import { api_url } from "../../../utils/constants";
import { FaSearch } from "react-icons/fa";
import { exportExcelFile } from "../../../utils/helpers";
import UserModal from "../../Shared/UserModal";
import UserMembersModal from "../../Shared/UserMembersModal";
import { useMainContext } from "../../../contexts/main_context";

function UserTable({ type }: any) {
	const { openUserModal, setUserDetails } = useMainContext();
	const [usersDetails, setUsersDetails]: any = useState([]);
	const [usersTotal, setUsersTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	//console.log("user", usersDetails);
	const handleChangePage = (page: any, pageSize: any) => {
		setCurrentPage(page);
		setPerPage(pageSize);
	};

	const [searchUser, setSearchUser] = useState("");
	const handleChangeSearch = (e: any) => {
		const val = e.target.value;
		setSearchUser(val);
	};

	//
	const handleUserDetails = (e: any) => {
		//console.log("user", e.target.id);
		axios_auth(Cookies.get("token"))
			.post(api_url + "/admin/user-details", { userId: e.target.id })
			.then((response: any) => {
				setUserDetails(response.data.data);
				//console.log("key", response.data.data);
				openUserModal();
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	};

	useEffect(() => {
		const url =
			type == 1
				? api_url + "/admin/activities"
				: api_url + "/admin/inactive-users";
		axios_auth(Cookies.get("token"))
			.get(url)
			.then((response: any) => {
				//console.log("key", response.data.data);
				setUsersDetails(response.data.data.data);
				setUsersTotal(response.data.data.total);
				// setCurrentPage(response.data.data.current_page);
				// setPerPage(response.data.data.per_page);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, []);

	// for changing the page
	useEffect(() => {
		var url =
			type == 1
				? api_url +
				  "/admin/activities?page=" +
				  currentPage +
				  "&perPage=" +
				  perPage
				: api_url +
				  "/admin/inactive-users?page=" +
				  currentPage +
				  "&perPage=" +
				  perPage;

		if (searchUser.length >= 3) {
			url += "&email=" + searchUser;
			//console.log("url", url);
		}

		axios_auth(Cookies.get("token"))
			.get(url)
			.then((response: any) => {
				//console.log("key", response.data.data);
				setUsersDetails(response.data.data.data);
				setUsersTotal(response.data.data.total);
				setCurrentPage(
					response.data.data.current_page > response.data.data.last_page
						? 1
						: response.data.data.current_page
				);
				setPerPage(response.data.data.per_page);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, [currentPage, type, searchUser, perPage]);

	const handleExport = () => {
		// exportExcelFile(usersDetails, type == 1 ? "activeUsers" : "in-activeUsers");
		axios_auth(Cookies.get("token"))
			.get(api_url + "/admin/export-user", {
				responseType: "blob", // Important for binary data
			})
			.then((response: any) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				// Create a link element
				const link = document.createElement("a");
				link.href = url;
				link.download = "users.xlsx"; // Specify the downloaded file name
				link.target = "_blank";
				link.rel = "Users";

				// Dispatch a click event on the link
				link.dispatchEvent(new MouseEvent("click"));
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	};

	return (
		<div className="flex flex-col items-start w-full mt-10">
			<div className="flex flex-row items-center justify-end relative py-2 w-full space-x-4">
				<button
					className="bg-clr-main-bright py-2 px-5 rounded-lg text-clr-main-dark font-bold hover:shadow-sm hover:shadow-clr-main-bright"
					onClick={handleExport}
				>
					Export
				</button>
				<div className="flex flex-row items-center ">
					<label
						htmlFor="search"
						className="text-clr-background absolute right-2"
					>
						<FaSearch className="w-5" />
					</label>
					<input
						id="search"
						type="text"
						className="py-2 px-5 rounded-md text-clr-main-dark"
						placeholder="Search"
						value={searchUser}
						onChange={(e) => handleChangeSearch(e)}
					/>
				</div>
			</div>

			{usersDetails.length > 0 && (
				<div className="w-full flex flex-col justify-center space-y-3">
					<div className="mx-auto  border rounded-lg w-full overflow-x-auto">
						<table className="divide-y divide-gray-200 min-w-full table-auto">
							<thead className="bg-clr-secondary-bright">
								<tr className="rounded-t-xl">
									{/* <th className="py-2 px-4 "></th> */}
									<th className="py-2 px-4 ">Name</th>
									<th className="py-2 px-4 ">Email</th>
									<th className="py-2 px-4 ">Referral Code</th>
									<th className="py-2 px-4 ">Base Investment</th>
									<th className="py-2 px-4 ">Created At</th>
								</tr>
							</thead>
							<tbody className="">
								{usersDetails.length > 0 &&
									usersDetails.map((item: any) => {
										return (
											<tr
												key={item.id}
												className={"cursor-pointer hover:bg-clr-gray-light "}
											>
												{/* <td className="py-2 px-1  text-center text-clr-gray-dark font-medium "></td> */}
												<td
													className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal hover:text-clr-main-bright"
													id={item.id}
													onClick={handleUserDetails}
												>
													{/* {item.direct_parent_name} */}
													{item.name}
												</td>
												<td
													className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal hover:text-clr-main-bright"
													id={item.id}
													onClick={handleUserDetails}
												>
													{/* {item.direct_parent_name} */}
													{item.email}
												</td>
												<td className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal ">
													{/* {item.child_name} */}
													{item.referral_code}
												</td>
												<td className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal ">
													{/* {item.created_at} */}
													{item.init_balance}
												</td>
												<td className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal ">
													{/* {item.created_at} */}
													{format(new Date(item.created_at), "y-M-d")}
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>

					<div>
						<Pagination
							className={"custom-pagination "}
							defaultCurrent={currentPage}
							total={usersTotal}
							pageSize={perPage}
							onChange={handleChangePage}
							pageSizeOptions={[10, 20, 50, 100]}
							hideOnSinglePage={true}
							showSizeChanger={true}
						/>
					</div>
				</div>
			)}

			{usersDetails.length <= 0 && (
				<div className="flex flex-col items-center justify-center mx-auto">
					<p className="text-clr-off-white text-4xl py-5 font-semibold">
						There is no Active User Today
					</p>
				</div>
			)}

			<UserModal />
			<UserMembersModal />
		</div>
	);
}

export default UserTable;
