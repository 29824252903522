import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";

export const api_url =
	process.env.NODE_ENV === "production"
		? process.env.REACT_APP_Back_URL_SERVER
		: process.env.REACT_APP_Back_URL_TEST;

export const navbarList = [
	{
		id: "navList_1",
		text: "Home",
		href: "/",
	},
	{
		id: "navList_4",
		text: "Sign UP",
		href: "/register",
	},
	{
		id: "navList_5",
		text: "Login",
		href: "/login",
	},
];

export const socialList = [
	{
		id: "socialList_1",
		icon: <FaLinkedinIn className="w-5 h-5 font-bold text-4xl" />,
		href: "https://www.linkedin.com/company/tolcoin/",
	},
	{
		id: "socialList_2",
		icon: <FaInstagram className="w-5 h-5 font-bold text-4xl" />,
		href: "https://www.instagram.com/tolcoin/",
	},
	{
		id: "socialList_3",
		icon: <FaFacebookF className="w-5 h-5 font-bold text-4xl" />,
		href: "https://www.facebook.com/people/ToLcoin/100092447538246/",
	},
];

export const footerLinks = [
	{
		id: "footer_col_1",
		name: "Product",
		options: [
			{
				id: "footer_col_1_1",
				text: "Buy ToL",
				href: "/invest",
			},
			{
				id: "footer_col_1_2",
				text: "Pricing",
				href: "/",
			},
			{
				id: "footer_col_1_3",
				text: "Change Log",
				href: "/",
			},
			{
				id: "footer_col_1_4",
				text: "Login",
				href: "/login",
			},
			{
				id: "footer_col_1_5",
				text: "Sign Up",
				href: "/register",
			},
		],
	},
	{
		id: "footer_col_2",
		name: "Company",
		options: [
			{
				id: "footer_col_2_1",
				text: "About",
				href: "/about",
			},
			{
				id: "footer_col_2_5",
				text: "White Paper",
				href: "/whitepaper",
			},
			{
				id: "footer_col_2_2",
				text: "Careers",
				href: "/",
			},
			{
				id: "footer_col_2_3",
				text: "Blogs",
				href: "https://medium.com/@tolcoinn",
			},
			{
				id: "footer_col_2_4",
				text: "Contact Us",
				href: "/",
			},
		],
	},
	{
		id: "footer_col_3",
		name: "Resources",
		options: [
			{
				id: "footer_col_3_1",
				text: "Terms Of Service",
				href: "/",
			},
			{
				id: "footer_col_3_2",
				text: "Privacy Policy",
				href: "/",
			},
			{
				id: "footer_col_3_3",
				text: "FAQ's",
				href: "/",
			},
		],
	},
];
