// main navbar
export const SIDEBAR_OPEN = 'SIDEBAR_OPEN'
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE'

// selected plan
export const SELECTED_PLAN = "SELECTED_PLAN"

// SET SECTION
export const SET_SECTION = "SET_SECTION"
export const SET_LEVEL = "SET_LEVEL"
export const SET_CURRENT_USER = "SET_CURRENT_USER"

// puplic key - address
export const SET_ADDRESS = 'SET_ADDRESS'

// Withdrawal
export const OPEN_MODAL_REQUEST = 'OPEN_MODAL_REQUEST'
export const CLOSE_MODAL_REQUEST = 'CLOSE_MODAL_REQUEST'

export const OPEN_MODAL_PRIVATE = 'OPEN_MODAL_PRIVATE'
export const CLOSE_MODAL_PRIVATE = 'CLOSE_MODAL_PRIVATE'

// SET Rewards type
export const SET_REWARDS_TYPE = "SET_REWARDS_TYPE"

// user details
export const OPEN_MODAL_USER = 'OPEN_MODAL_USER'
export const CLOSE_MODAL_USER = 'CLOSE_MODAL_USER'

export const SET_USER_DETAILS = 'SET_USER_DETAILS'

export const OPEN_MODAL_USER_MEMBER = 'OPEN_MODAL_USER_MEMBER'
export const CLOSE_MODAL_USER_MEMBER = 'CLOSE_MODAL_USER_MEMBER'

export const SET_USER_MEMBERS = 'SET_USER_MEMBERS'