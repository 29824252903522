import React, { useState, useEffect, useRef } from "react";

// lib
import { Pagination } from "antd";
import { FaEdit, FaSearch } from "react-icons/fa";
import { BsEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// custom
import { useMainContext } from "../../../contexts/main_context";
import RequestDetails from "./RequestDetails";
import ConfirmWallet from "./ConfirmWallet";
import {
	exportExcelFile,
	handleRequestStatusClassName,
} from "../../../utils/helpers";
import { axios_auth } from "../../../utils/axios";
import { api_url } from "../../../utils/constants";
import UseRemoveInputFocus from "../../../hooks/UseRemoveInputFocus";
import UserModal from "../../Shared/UserModal";
import UserMembersModal from "../../Shared/UserMembersModal";

function RequestsTable({
	reqData,
	dataTotal,
	pageNo,
	changePage,
	changeSearchVal,
	currentPageSize,
	changePageSize,
}: any) {
	const { openRequestModal, openUserModal, setUserDetails } = useMainContext();
	const [requestsDetails, setRequestsDetails]: any = useState([]);
	// const [requestTotal, setRequestsTotal] = useState(0);
	const [pageCur, setPageCur] = useState(pageNo);
	const [perCurPage, setPerCurPage] = useState(currentPageSize);

	// status
	const [isOpenList, setOpenList] = useState(false);
	const [filteredStatus, setFilteredStatus] = useState("all");
	const handleSelectedStatus = (status: string) => {
		//console.log("test", status);
		setFilteredStatus(status);
		handleExport(status);
		setOpenList(false);
	};
	const statusListRef: any = useRef();
	UseRemoveInputFocus(statusListRef, () => setOpenList(false));

	//
	const handleUserDetails = (e: any) => {
		//console.log("user", e.target.id);
		axios_auth(Cookies.get("token"))
			.post(api_url + "/admin/user-details", { userId: e.target.id })
			.then((response: any) => {
				setUserDetails(response.data.data);
				//console.log("key", response.data.data);
				openUserModal();
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	};
	// search
	const [searchUser, setSearchUser] = useState("");
	const handleChangeSearch = (e: any) => {
		const val = e.target.value;
		setSearchUser(val);
		changeSearchVal(val);
	};

	const handleChangePage = (page: any, pageSize: any) => {
		setPageCur(page);
		setPerCurPage(pageSize);
	};

	//modal
	const handleRequest = (e: any) => {
		const id = e.currentTarget.id;
		const req = requestsDetails.find((item: any) => item.id == id);
		openRequestModal(req);
		//console.log("id", id, req);
	};

	useEffect(() => {
		if (reqData != undefined) {
			setRequestsDetails(reqData.data);
		}
	}, [reqData]);

	useEffect(() => {
		changePage(pageCur);
	}, [pageCur]);

	useEffect(() => {
		changePageSize(perCurPage);
	}, [perCurPage]);

	const handleExport = (param: string) => {
		//exportExcelFile(requestsDetails, "Withdrawals");
		axios_auth(Cookies.get("token"))
			.get(api_url + "/admin/export-withdrawals?status=" + param, {
				responseType: "blob", // Important for binary data
			})
			.then((response: any) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				// Create a link element
				const link = document.createElement("a");
				link.href = url;
				link.download = "withdrawals.xlsx"; // Specify the downloaded file name
				link.target = "_blank";
				link.rel = "withdrawals";

				// Dispatch a click event on the link
				link.dispatchEvent(new MouseEvent("click"));
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	};

	return (
		<div className="flex flex-col items-start w-full mt-10 relative">
			<div className="flex flex-row items-center justify-end relative py-2 w-full space-x-4">
				{/* export button */}
				<div className="relative">
					<button
						className="bg-clr-main-bright py-2 px-5 rounded-lg text-clr-main-dark font-bold hover:shadow-sm hover:shadow-clr-main-bright"
						onClick={() => setOpenList(!isOpenList)}
					>
						Export
					</button>
					{isOpenList && (
						<ul
							className="z-100 absolute bg-clr-secondary-bright p-3 rounded mt-1 shadow-2xl text-base font-semibold"
							ref={statusListRef}
						>
							<li
								className="border-b border-gray-400 py-1 px-2 rounded-t hover:bg-clr-main-bright hover:border-clr-main-dark cursor-pointer hover:text-clr-main-dark"
								onClick={() => handleSelectedStatus("all")}
							>
								All
							</li>
							<li
								className="border-b border-gray-400 py-1 px-2 rounded-t hover:bg-clr-main-bright hover:border-clr-main-dark cursor-pointer hover:text-clr-main-dark"
								onClick={() => handleSelectedStatus("pending")}
							>
								Pending
							</li>
							<li
								className="border-b border-gray-400 py-1 px-2 rounded-t hover:bg-clr-main-bright hover:border-clr-main-dark cursor-pointer hover:text-clr-main-dark"
								onClick={() => handleSelectedStatus("paid")}
							>
								Paid
							</li>
							<li
								className="border-b border-gray-400 py-1 px-2 rounded-t hover:bg-clr-main-bright hover:border-clr-main-dark cursor-pointer hover:text-clr-main-dark"
								onClick={() => handleSelectedStatus("canceled")}
							>
								Canceled
							</li>
						</ul>
					)}
				</div>
				{/* search input */}
				<div className="flex flex-row items-center">
					<label
						htmlFor="search"
						className="text-clr-background absolute right-2"
					>
						<FaSearch className="w-5" />
					</label>
					<input
						id="search"
						type="text"
						className="py-2 px-5 rounded-md text-clr-main-dark"
						placeholder="Search"
						value={searchUser}
						onChange={(e) => handleChangeSearch(e)}
					/>
				</div>
			</div>
			{requestsDetails.length > 0 && (
				<div className="w-full flex flex-col space-y-3">
					<div className="overflow-hidden border rounded-lg w-full">
						<table className="min-w-full divide-y divide-gray-200 overflow-x-scroll">
							<thead className="bg-clr-secondary-bright">
								<tr className="rounded-t-xl">
									<th className="py-2 px-4 "></th>
									<th className="py-2 px-4 ">Name</th>
									<th className="py-2 px-4 ">Email</th>
									<th className="py-2 px-4 ">Amount</th>
									<th className="py-2 px-4 ">Date</th>
									<th className="py-2 px-4 ">status</th>
								</tr>
							</thead>
							<tbody className="">
								{requestsDetails.length > 0 &&
									requestsDetails.map((item: any) => {
										return (
											<tr
												key={item.id}
												className={"cursor-pointer hover:bg-clr-gray-light "}
											>
												<td className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal">
													<span className="flex items-center justify-center">
														{item.status === "pending" && (
															<FaEdit
																id={item.id}
																className="text-clr-main-bright hover:w-5 hover:h-5"
																onClick={handleRequest}
															/>
														)}
													</span>
												</td>
												<td
													className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal hover:text-clr-main-bright"
													id={item.user.id}
													onClick={handleUserDetails}
												>
													{item.user.name}
												</td>
												<td
													className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal hover:text-clr-main-bright"
													id={item.user.id}
													onClick={handleUserDetails}
												>
													{item.user.email}
												</td>
												<td className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal ">
													{/* {item.direct_parent_name} */}
													{item.amount}
												</td>
												<td className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal ">
													{/* {item.child_name} */}
													{item.date}
												</td>
												<td className="py-2 px-1 text-center text-clr-gray-dark font-medium border border-clr-gray-normal flex flex-row items-center justify-center space-x-2">
													{/* {item.created_at} */}
													<span
														className={handleRequestStatusClassName(
															item.status
														)}
													>
														{item.status}
													</span>
													{item.status == "paid" &&
														item.transaction_hash != null && (
															<Link
																to={
																	"https://bscscan.com/tx/" +
																	item.transaction_hash
																}
																className="text-clr-main-bright"
															>
																<BsEyeFill />
															</Link>
														)}
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>

					<div>
						<Pagination
							className={"custom-pagination "}
							defaultCurrent={pageNo}
							total={dataTotal}
							pageSize={currentPageSize}
							onChange={handleChangePage}
							pageSizeOptions={[10, 20, 50, 100]}
							hideOnSinglePage={false}
							showSizeChanger={true}
						/>
					</div>
				</div>
			)}

			{requestsDetails.length <= 0 && (
				<div className="flex flex-col items-center justify-center mx-auto">
					<p className="text-clr-off-white text-4xl py-5 font-semibold">
						There is no Request Today
					</p>
				</div>
			)}

			<RequestDetails />
			<ConfirmWallet />
			<UserModal />
			<UserMembersModal />
		</div>
	);
}

export default RequestsTable;
