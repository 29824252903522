import React, { useState, useEffect } from "react";

// lib
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// custom
import { classNames } from "../../utils/helpers";
import { api_url } from "../../utils/constants";
import { axios_auth } from "../../utils/axios";

function WalletPage() {
	const navigate = useNavigate();
	const [copiedKey, setCopiedKey] = useState(false);
	const [downloadedKey, setDownloadedKey] = useState(false);
	const [keyP, setkeyP] = useState("");

	const handleCopy = () => {
		const key: any = document.getElementById("key");
		navigator.clipboard.writeText(key.innerHTML);
		setCopiedKey(true);
	};

	const handleDownload = () => {
		const key: any = document.getElementById("key");
		html2canvas(key).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF();
			//pdf.addImage(imgData, 'JPEG', 50, 20 , 100 , 30)
			pdf.text(key.innerHTML, 5, 20);
			pdf.save("private-key.pdf");
			setDownloadedKey(true);
		});
	};

	const handleKey = () => {
		navigate("/user/dashboard");
	};

	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.post(api_url + "/wallet/create")
			.then((response: any) => {
				console.log("key", response.data.data.wallet.private_key);
				setkeyP(response.data.data.wallet.private_key);
			})
			.catch((error) => {
				navigate("/user/dashboard");
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, []);

	return (
		<div className="h-auto relative overflow-hidden flex lg:items-center items-start lg:justify-center justify-start">
			{/* bolb */}
			<div
				id="bolb"
				className="absolute w-[300px] h-[300px] S-xl:-top-[5px] lg:top-1 -top-25 right-[100px] rounded-full"
			></div>
			{/* end bolb */}
			<div className="flex flex-col relative top-14 mb-20 mx-auto">
				<h1
					className={
						"relative text-center text-clr-main-bright text-4xl md:mt-16 mt-10 mb-0 md:p-10 p-6 font-bold"
					}
				>
					Save your private key
				</h1>
				<p
					className={
						"relative text-base p-2 w-[80%] mx-auto text-clr-off-white"
					}
				>
					Below is your Private Key. It is very important that you copy it and
					keep it safe. If it is lost,you will not be able to Login to your
					account.
				</p>
				<div
					className={
						"flex flex-col text-base text-clr-main-bright rounded-xl shadow-card py-8 px-2 w-[70%] mx-auto bg-transparent z-10 border border-clr-main-bright"
					}
				>
					<p
						className={
							"relative text-base p-2 mx-auto text-clr-gray-dark break-all"
						}
						id={"key"}
					>
						{keyP}
					</p>
				</div>
				<div
					className={
						"my-8 flex md:flex-row flex-col items-center justify-center"
					}
				>
					<button
						className={
							"py-2 px-4 my-1 text-clr-main-dark text-lg bg-clr-main-bright rounded-full mx-16 shadow-input z-30 border-none transition ease-in-out delay-150 hover:bg-clr-secondary-bright"
						}
						onClick={handleCopy}
					>
						copy private key
					</button>
					<button
						className={
							"py-2 px-4 my-1 text-clr-main-dark text-lg bg-clr-main-bright rounded-full mx-16 shadow-input z-30 border-none transition ease-in-out delay-150 hover:bg-clr-secondary-bright"
						}
						onClick={handleDownload}
					>
						Download PDF
					</button>
				</div>

				<p
					className={
						"text-lg text-clr-secondary-bright text-center underline font-semibold italic px-2 mb-3"
					}
				>
					You need to copy and download the Private Key before you can continue
				</p>
				{/* stylesKey.startBox */}
				<div className={"flex items-center justify-center"} id="startBox">
					<button
						className={classNames(
							!copiedKey || !downloadedKey ? "opacity-60" : "",
							"text-white text-lg font-bold bg-clr-main-bright disabled:bg-clr-secondary-bright hover:bg-clr-teal-hover cursor-pointer disabled:cursor-default py-2 px-5 rounded-full z-30 shadow-input border-none transition ease-in-out delay-150 "
						)}
						disabled={!copiedKey || !downloadedKey}
						onClick={() => handleKey()}
					>
						Start Your Journey
					</button>
				</div>
			</div>
		</div>
	);
}

export default WalletPage;
