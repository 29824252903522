import React from "react";

// custom
import { useAuthContext } from "../../contexts/auth_context";

function NotAllowed() {
	const { logout, user } = useAuthContext();
	return (
		<div className="sm:col-span-9 col-span-12">
			<div className="flex flex-col space-y-10">
				{/* <div className="py-4 border-b border-clr-main-bright flex lg:flex-row lg:space-y-0 flex-col space-y-1 lg:items-center items-start justify-between">
					<p>
						<span>Name: </span> <span>{user.user.name}</span>
					</p>
					<p>
						<span>Email: </span> <span>{user.user.email}</span>
					</p>
					<p>
						<span>Referral code: </span> <span>{user.user.referral_code}</span>
					</p>
				</div> */}
				<div className="flex flex-col mx-auto items-center justify-center text-center">
					<section className="mt-20 flex flex-col space-y-10">
						<h1 className="text-9xl font-semibold">401</h1>
						<h3 className="tracking-wide capitalize mb-8 leading-7 text-2xl">
							Sorry - Authorization Required, you don't have access
						</h3>
						<button
							onClick={logout}
							className="w-1/2 mx-auto hover:uppercase capitalize transition-all ease-in-out delay-500 px-6 py-3 bg-clr-main-bright text-clr-main-dark font-medium  rounded-lg shadow-clr-main-bright"
						>
							Logout
						</button>
					</section>
				</div>
			</div>
		</div>
	);
}

export default NotAllowed;
