import React from "react";

// lib
import { FaArrowRight } from "react-icons/fa";
import { useMainContext } from "../../../contexts/main_context";

function RewardsCards({ changeType }: any) {
	return (
		<div className="flex flex-col mx-auto items-center justify-center">
			<div className="grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-5 w-full relative">
				{/* card */}
				<div
					className="group flex flex-row items-center justify-between space-x-28 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg py-5 px-8 cursor-pointer"
					id="borderTest"
					onClick={() => changeType("self")}
				>
					<div className="flex flex-col items-start">
						<span className="text-xl text-clr-off-white font-bold">
							Staking Rewards
						</span>
					</div>
					<FaArrowRight className="text-clr-main-bright group-hover:bg-clr-main-bright group-hover:text-clr-main-dark rounded-full w-8 h-8 py-2" />
				</div>
				{/* card */}
				<div
					className="group flex flex-row items-center justify-between space-x-28 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg py-5 px-8 cursor-pointer"
					id="borderTest"
					onClick={() => changeType("direct")}
				>
					<div className="flex flex-col items-start">
						<span className="text-xl text-clr-off-white font-bold">
							Direct Rewards
						</span>
					</div>
					<FaArrowRight className="text-clr-main-bright group-hover:bg-clr-main-bright group-hover:text-clr-main-dark rounded-full w-8 h-8 py-2" />
				</div>
				{/* card */}
				<div
					className="group flex flex-row items-center justify-between space-x-28 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg py-5 px-8 cursor-pointer"
					id="borderTest"
					onClick={() => changeType("in-direct")}
				>
					<div className="flex flex-col items-start">
						<span className="text-xl text-clr-off-white font-bold">
							In-Direct Rewards
						</span>
					</div>
					<FaArrowRight className="text-clr-main-bright group-hover:bg-clr-main-bright group-hover:text-clr-main-dark rounded-full w-8 h-8 py-2" />
				</div>
			</div>
		</div>
	);
}

export default RewardsCards;
