import React, { useState, useEffect } from "react";

// lib
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// custom
import { classNames } from "../../utils/helpers";
import { useAuthContext } from "../../contexts/auth_context";
import Aside from "../../components/Shared/Aside";
import Rect from "../../assets/Rect_base.svg";
import CoinBtn from "../../assets/coin_btn.svg";
import { FaArrowCircleUp, FaArrowDown, FaArrowUp } from "react-icons/fa";
import { axios_auth } from "../../utils/axios";
import { api_url } from "../../utils/constants";
import BaseDetails from "../../components/Client/Dashboard/BaseDetails";
import AppDetails from "../../components/Client/Dashboard/AppDetails";
import NotAllowed from "../../components/Shared/NotAllowed";

function DashboardPage() {
	const { logout, user } = useAuthContext();
	const [isOpen, setIsOpen] = useState(false);
	const [homeDetails, setHomeDetails]: any = useState([]);
	let location = useLocation();
	const navigate = useNavigate();

	const handleStaking = async () => {
		//console.log("test");
		axios_auth(Cookies.get("token"))
			.post(api_url + "/stake")
			.then((response: any) => {
				//console.log(response.data);
				if (response.data.status) {
					toast.success("Staking is successful", {
						position: "top-left",
					});
				} else {
					toast.error(response.data.message, {
						position: "top-left",
					});
				}
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	};
	//console.log("test", user.user.isAdmin);

	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.get(api_url + "/home/stats")
			.then((response: any) => {
				//console.log("key", response.data.data);
				setHomeDetails(response.data.data);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, []);

	// useEffect(() => {
	// 	if (!homeDetails.subscription) {
	// 		navigate("/plans");
	// 	}
	// }, []);

	return (
		<div className="my-10 h-auto  relative  flex lg:items-center items-start lg:justify-center justify-start">
			{/* bolb */}
			<div
				id="bolb"
				className="absolute w-[300px] h-[300px] S-xl:-top-[5px] lg:top-1 -top-25 right-[100px] rounded-full"
			></div>
			{/* end bolb */}
			<div className="mx-auto lg:my-0 my-20 lg:max-w-max-custom max-w-max-width w-90vw">
				<div className="grid grid-cols-12 gap-5">
					{/* aside */}
					<Aside />
					{/* content */}
					<div className="S-950:col-span-9 col-span-12">
						<div className="flex flex-col space-y-6">
							<div className="py-4 border-b border-clr-main-bright flex lg:flex-row lg:space-y-0 flex-col space-y-1 lg:items-center items-start justify-between">
								<p>
									<span>Name: </span> <span>{user.user.name}</span>
								</p>
								<p>
									<span>Email: </span> <span>{user.user.email}</span>
								</p>
								<p>
									<span>Referral code: </span>{" "}
									<span>{user.user.referral_code}</span>
								</p>
							</div>
							{user.user.isAdmin ? <AppDetails /> : <NotAllowed />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DashboardPage;
