import React, { useState, useEffect } from "react";

// lib

// custom
import Aside from "../../components/Shared/Aside";
import { useAuthContext } from "../../contexts/auth_context";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useMainContext } from "../../contexts/main_context";
import RewardsCards from "../../components/Client/Rewards/RewardsCards";
import NotAllowed from "../../components/Shared/NotAllowed";
import SelfRewards from "../../components/Client/Rewards/SelfRewards";
import DirectRewards from "../../components/Client/Rewards/DirectRewards";
import InDirectRewards from "../../components/Client/Rewards/InDirectRewards";

function RewardsPage() {
	const { user } = useAuthContext();
	const { currentRewardsType, setCurrentRewardsType } = useMainContext();
	const [getRewardsType, setRewardsType] = useState("");

	console.log("test test", getRewardsType);

	// useEffect(() => {
	// 	console.log("type", getRewardsType);
	// 	setCurrentRewardsType(getRewardsType);
	// }, [getRewardsType]);

	return (
		<div className="my-10 h-auto  relative overflow-hidden flex lg:items-center items-start lg:justify-center justify-start">
			{/* bolb */}
			<div
				id="bolb"
				className="absolute w-[300px] h-[300px] S-xl:-top-[5px] lg:top-1 -top-25 right-[100px] rounded-full"
			></div>
			{/* end bolb */}
			<div className="mx-auto lg:my-0 my-20 lg:max-w-max-custom max-w-max-width w-90vw">
				<div className="grid grid-cols-12 gap-5">
					{/* aside */}
					<Aside />
					{/* content */}
					<div className="S-950:col-span-9 col-span-12">
						<div className="flex flex-col space-y-6">
							<div className="py-4 border-b border-clr-main-bright flex lg:flex-row lg:space-y-0 flex-col space-y-1 lg:items-center items-start justify-between">
								<p>
									<span>Name: </span> <span>{user.user.name}</span>
								</p>
								<p>
									<span>Email: </span> <span>{user.user.email}</span>
								</p>
								<p>
									<span>Referral code: </span>{" "}
									<span>{user.user.referral_code}</span>
								</p>
							</div>
							{getRewardsType !== "" && (
								<div className="flex flex-col items-end justify-end">
									<button
										type="button"
										onClick={() => setRewardsType("")}
										className="px-3 py-1 bg-clr-main-bright text-clr-main-dark hover:text-clr-off-white flex flex-row items-center space-x-2 rounded-full"
									>
										<FaArrowLeft />
										<span className="font-bold">Back</span>
									</button>
								</div>
							)}

							{/* CARDS */}
							{getRewardsType == "" && (
								<RewardsCards changeType={setRewardsType} />
							)}

							{getRewardsType == "self" && <SelfRewards />}

							{getRewardsType == "direct" && <DirectRewards />}

							{getRewardsType == "in-direct" && <InDirectRewards />}
							{/* {user.user.isAdmin ? (
								<>
									
								</>
							) : (
								<NotAllowed />
							)} */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default RewardsPage;
