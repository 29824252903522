import React from "react";
import { useMainContext } from "../../../contexts/main_context";
import { classNames } from "../../../utils/helpers";

function SinglePlan({ data, choosed }: any) {
	const { selectedPlan, setSelectedPlan } = useMainContext();

	const handlePlanId = (id: any) => {
		const data = {
			package_id: id,
			base_investment: selectedPlan.base_investment,
			type: selectedPlan.type,
			privateKey: selectedPlan.privateKey,
		};
		setSelectedPlan(data);
	};
	// /console.log("data", data);
	return (
		<div className="flex flex-col border border-clr-main-bright rounded-lg">
			<div className="flex flex-col items-center justify-center w-full rounded-lg">
				<h1 className="w-full bg-clr-main-bright text-clr-main-dark text-3xl font-bold rounded-t-lg py-2 text-center">
					{data.name}
				</h1>
			</div>
			<div className="flex flex-col pl-5 space-y-3 py-4">
				{/* duration */}
				<div className="flex flex-col">
					<span className="text-lg text-clr-off-white">Staking Duration</span>
					<span className="text-lg text-clr-main-bright">
						{data.duration / 30} Months
					</span>
				</div>
				{/* rewards */}
				<div className="flex flex-col">
					<span className="text-lg text-clr-off-white">Staking Reward</span>
					<span className="text-lg text-clr-main-bright">
						{Math.round(
							parseFloat(data.self_rewards_rate) * parseInt(data.duration) * 100
						)}{" "}
						%
					</span>
				</div>
				{/* direct */}
				<div className="flex flex-col">
					<span className="text-lg text-clr-off-white">Direct Reward</span>
					<span className="text-lg text-clr-main-bright">
						{data.direct_rewards_rate * 100} %
					</span>
				</div>
				{/* level */}
				<div className="flex flex-col">
					<span className="text-lg text-clr-off-white">Level UnLocked</span>
					<span className="text-lg text-clr-main-bright">
						{data.levels} Levels
					</span>
				</div>
				{/* income */}
				<div className="flex flex-col">
					<span className="text-lg text-clr-off-white">Level Income</span>
					<span className="text-lg text-clr-main-bright">
						{data.staking_rewards} %
					</span>
				</div>
				{/* duration */}
				<div className="flex flex-col items-end">
					<button
						type="button"
						className={classNames(
							selectedPlan.package_id == data.id
								? "text-clr-main-dark bg-clr-main-bright"
								: "text-clr-main-bright bg-transparent",
							"border border-clr-main-bright rounded-lg  font-semibold text-lg px-2 py-2 w-1/2 mr-8"
						)}
						onClick={() => handlePlanId(data.id)}
					>
						{selectedPlan.package_id == data.id ? "Selected" : "Select"}
					</button>
				</div>
			</div>
		</div>
	);
}

export default SinglePlan;
