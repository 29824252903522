import React, { useState, useEffect, useRef } from "react";

// lib
import { BiMoneyWithdraw } from "react-icons/bi";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// custom
import { classNames } from "../../../utils/helpers";
import { FaCheckCircle } from "react-icons/fa";
import RequestsTable from "./RequestsTable";
import { axios_auth } from "../../../utils/axios";
import { api_url } from "../../../utils/constants";

function WithdrawalsContent() {
	const [homeDetails, setHomeDetails]: any = useState([]);
	const [requestTotal, setRequestsTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [perCurrentPage, setPerCurrentPage] = useState(10);
	const [userType, setUserType] = useState(1);
	const [searchVal, setSearchVal] = useState("");

	// console.log("searchVal", searchVal);

	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.get(api_url + "/admin/withdrawals?filter[status]=pending")
			.then((response: any) => {
				//console.log("key", response.data.data);
				setHomeDetails(response.data.data);
				setRequestsTotal(response.data.data.withdrawals.total);
				setCurrentPage(response.data.data.withdrawals.current_page);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, []);

	useEffect(() => {
		var url =
			userType == 1
				? api_url +
				  "/admin/withdrawals?filter[status]=pending&page=" +
				  currentPage +
				  "&perPage=" +
				  perCurrentPage
				: userType == 2
				? api_url +
				  "/admin/withdrawals?filter[status]=paid&page=" +
				  currentPage +
				  "&perPage=" +
				  perCurrentPage
				: api_url +
				  "/admin/withdrawals?filter[status]=canceled&page=" +
				  currentPage +
				  "&perPage=" +
				  perCurrentPage;

		if (searchVal.length >= 3) {
			url += "&q=" + searchVal;
		}

		axios_auth(Cookies.get("token"))
			.get(url)
			.then((response: any) => {
				//console.log("key", response.data.data);
				setHomeDetails(response.data.data);
				setRequestsTotal(response.data.data.withdrawals.total);
				setCurrentPage(
					response.data.data.withdrawals.current_page >
						response.data.data.withdrawals.last_page
						? 1
						: response.data.data.withdrawals.current_page
				);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, [userType, currentPage, searchVal, perCurrentPage]);

	return (
		<div className="flex flex-col mx-auto ">
			{/* cards */}
			<div className="flex flex-wrap items-center justify-center">
				<div className="flex flex-col text-center w-[300px] h-[150px] mx-5 my-3 items-center justify-center space-y-4 border border-clr-secondary-bright px-20 py-5 rounded-3xl bg-gradient-to-r from-clr-secondary-dark to-clr-secondary-bright">
					<span className="text-clr-off-white text-xl font-bold">
						Total Requests
					</span>
					<span className="text-clr-main-dark font-bold text-lg">
						{homeDetails?.totalCount ? homeDetails?.totalCount : 0}
					</span>
				</div>
				<div className="flex flex-col text-center w-[300px] h-[150px] mx-5 my-3 items-center justify-center space-y-4 border border-clr-secondary-bright px-20 py-5 rounded-3xl bg-gradient-to-r from-clr-secondary-dark to-clr-secondary-bright">
					<span className="text-clr-off-white text-xl font-bold">
						Pending Requests
					</span>
					<span className="text-clr-main-dark font-bold text-lg">
						{homeDetails?.pending_count ? homeDetails?.pending_count : 0}
					</span>
				</div>
				<div className="flex flex-col text-center w-[300px] h-[150px] mx-5 my-3 items-center justify-center space-y-4 border border-clr-secondary-bright px-20 py-5 rounded-3xl bg-gradient-to-r from-clr-secondary-dark to-clr-secondary-bright">
					<span className="text-clr-off-white text-xl font-bold">
						Paid Requests
					</span>
					<span className="text-clr-main-dark font-bold text-lg">
						{homeDetails?.paid_count ? homeDetails?.paid_count : 0}
					</span>
				</div>
			</div>
			{/* REquests */}
			<div className="flex flex-col space-y-3">
				<h1 className="flex flex-row items-center space-x-2 text-start text-4xl text-clr-main-bright font-semibold py-5">
					<BiMoneyWithdraw />
					<span>Withdrawals Requests</span>
				</h1>
				<ul className="flex flex-row space-x-5 ml-4 text-lg font-semibold">
					<li
						className={classNames(
							"flex flex-row items-center space-x-2 cursor-pointer",
							userType == 1
								? "text-clr-secondary-bright border-b border-clr-secondary-bright"
								: "text-clr-off-white"
						)}
						onClick={() => {
							setUserType(1);
						}}
					>
						{userType == 1 && <FaCheckCircle />}
						<span>Pending</span>
					</li>
					<li
						className={classNames(
							"flex flex-row items-center space-x-2 cursor-pointer",
							userType == 2
								? "text-clr-secondary-bright border-b border-clr-secondary-bright"
								: "text-clr-off-white"
						)}
						onClick={() => {
							setUserType(2);
						}}
					>
						{userType == 2 && <FaCheckCircle />}
						<span>Paid</span>
					</li>
					<li
						className={classNames(
							"flex flex-row items-center space-x-2 cursor-pointer",
							userType == 3
								? "text-clr-secondary-bright border-b border-clr-secondary-bright"
								: "text-clr-off-white"
						)}
						onClick={() => {
							setUserType(3);
						}}
					>
						{userType == 3 && <FaCheckCircle />}
						<span>Canceled</span>
					</li>
				</ul>
				<RequestsTable
					reqData={homeDetails.withdrawals}
					dataTotal={requestTotal}
					pageNo={currentPage}
					changePage={setCurrentPage}
					changeSearchVal={setSearchVal}
					currentPageSize={perCurrentPage}
					changePageSize={setPerCurrentPage}
				/>
			</div>
		</div>
	);
}

export default WithdrawalsContent;
