import React, { useState, useEffect } from "react";

// lib
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// custom
import Aside from "../../components/Shared/Aside";
import { useAuthContext } from "../../contexts/auth_context";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { axios_auth } from "../../utils/axios";
import { api_url } from "../../utils/constants";
import logo from "../../assets/logo-nav.png";
import bnb_logo from "../../assets/BNB_logo.svg";

function WalletDetailsPage() {
	const { logout, user } = useAuthContext();
	const [isOpen, setIsOpen] = useState(false);
	const [homeDetails, setHomeDetails]: any = useState([]);
	let location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.get(api_url + "/wallet/details")
			.then((response: any) => {
				console.log("key", response.data.data);
				setHomeDetails(response.data.data);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, []);

	return (
		<div className="my-10 h-auto  relative overflow-hidden flex lg:items-center items-start lg:justify-center justify-start">
			{/* bolb */}
			<div
				id="bolb"
				className="absolute w-[300px] h-[300px] S-xl:-top-[5px] lg:top-1 -top-25 right-[100px] rounded-full"
			></div>
			{/* end bolb */}
			<div className="mx-auto lg:my-0 my-20 lg:max-w-max-custom max-w-max-width w-90vw">
				<div className="grid grid-cols-12 gap-5">
					{/* aside */}
					<Aside />
					{/* content */}
					<div className="S-950:col-span-9 col-span-12">
						<div className="flex flex-col space-y-6">
							<div className="py-4 border-b border-clr-main-bright flex lg:flex-row lg:space-y-0 flex-col space-y-1 lg:items-center items-start justify-between">
								<p>
									<span>Name: </span> <span>{user.user.name}</span>
								</p>
								<p>
									<span>Email: </span> <span>{user.user.email}</span>
								</p>
								<p>
									<span>Referral code: </span>{" "}
									<span>{user.user.referral_code}</span>
								</p>
							</div>
							<div className="flex flex-col mx-auto items-center justify-center space-y-10">
								{/* staticts */}
								<div className="relative">
									<div
										id="borderTest"
										className=" flex flex-col space-y-10 items-start justify-center px-20 py-10 z-30 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg"
									>
										{/* row */}
										<div className="grid S-550:grid-cols-2 grid-cols-1 gap-x-52 gap-y-20">
											{/* col - Available Amount*/}
											<div className="flex flex-col ">
												<span className="text-lg font-semibold text-clr-off-white">
													Available Amount
												</span>
												<span className="text-base font-semibold text-clr-main-bright">
													{homeDetails?.stats?.availableAmount
														? homeDetails?.stats?.availableAmount
														: 0}
												</span>
											</div>
											{/* col - Staked Amount */}
											<div className="flex flex-col">
												<span className="text-lg font-semibold text-clr-off-white">
													Staked Amount
												</span>
												<span className="text-base font-semibold text-clr-main-bright">
													{homeDetails?.stats?.stakedAmount
														? homeDetails?.stats?.stakedAmount
														: 0}
												</span>
											</div>
											{/* col - My Team */}
											<div className="flex flex-col ">
												<span className="text-lg font-semibold text-clr-off-white">
													My Team
												</span>
												<span className="text-base font-semibold text-clr-main-bright">
													{homeDetails?.stats?.myTeam
														? homeDetails?.stats?.myTeam
														: 0}
												</span>
											</div>
											{/* col - My Rewards */}
											<div className="flex flex-col">
												<span className="text-lg font-semibold text-clr-off-white">
													My Rewards
												</span>
												<span className="text-base font-semibold text-clr-main-bright">
													{homeDetails?.stats?.myRewards
														? homeDetails?.stats?.myRewards
														: 0}
												</span>
											</div>
										</div>
										{/* row */}
										{isOpen && (
											<div className="flex flex-col space-y-10 pt-8">
												<h1 className="text-2xl font-semibold text-clr-main-bright text-center">
													Global State
												</h1>
												<div className="grid S-550:grid-cols-2 grid-cols-1 gap-20">
													{/* col - Participants*/}
													<div className="flex flex-col ">
														<span className="text-lg font-semibold text-clr-off-white w-1/2">
															Total Participants of ToL Network
														</span>
														<span className="text-base font-semibold text-clr-main-bright">
															{homeDetails?.stats?.totalParticipantsOfTolNetwork
																? homeDetails?.stats
																		?.totalParticipantsOfTolNetwork
																: 0}
														</span>
													</div>
													{/* col - Staked In Pool */}
													<div className="flex flex-col">
														<span className="text-lg font-semibold text-clr-off-white w-1/2">
															Total ToL Staked In Pool
														</span>
														<span className="text-base font-semibold text-clr-main-bright">
															{homeDetails?.stats?.TotalTolStakedInPool
																? homeDetails?.stats?.TotalTolStakedInPool
																: 0}
														</span>
													</div>
													{/* col - Total Rewards (ToL) */}
													<div className="flex flex-col ">
														<span className="text-lg font-semibold text-clr-off-white w-1/2">
															Total Rewards (ToL)
														</span>
														<span className="text-base font-semibold text-clr-main-bright">
															{homeDetails?.stats?.totalRewardsTol
																? homeDetails?.stats?.totalRewardsTol
																: 0}
														</span>
													</div>
													{/* col - Total Rewards (USD) */}
													<div className="flex flex-col">
														<span className="text-lg font-semibold text-clr-off-white w-1/2">
															Total Rewards (USD)
														</span>
														<span className="text-base font-semibold text-clr-main-bright">
															{homeDetails?.stats?.totalRewardsUsd
																? homeDetails?.stats?.totalRewardsUsd
																: 0 + " $"}
														</span>
													</div>
												</div>
											</div>
										)}

										<button
											onClick={() => setIsOpen(!isOpen)}
											className="border border-clr-secondary-bright bg-clr-main-dark p-2 rounded-full text-clr-main-bright absolute -bottom-0 z-200 left-1/2"
										>
											{isOpen ? <FaArrowUp /> : <FaArrowDown />}
										</button>
									</div>
								</div>
								{/* balance */}
								<div className="flex flex-col items-center justify-center space-y-5 w-full">
									{/* tol */}
									<div className="flex flex-row justify-between px-10 py-5 rounded-lg w-full bg-gradient-to-r from-clr-secondary-dark to-clr-secondary-bright">
										<p className="flex flex-row space-x-2 items-center justify-center">
											<img
												className="-ml-4 w-8 h-8"
												src={logo}
												alt="Tree Of Life"
											/>
											<span className="text-clr-off-white font-semibold text-xl">
												{homeDetails?.tolCoinBalance}
											</span>
										</p>
										<p className="text-clr-off-white font-semibold text-xl">
											TOL
										</p>
									</div>
									{/* BNB */}
									<div className="flex flex-row justify-between px-10 py-5 rounded-lg w-full bg-gradient-to-r from-clr-secondary-dark to-clr-secondary-bright">
										<p className="flex flex-row space-x-2 items-center justify-center">
											<img
												className="-ml-4 w-8 h-8"
												src={bnb_logo}
												alt="Tree Of Life"
											/>
											<span className="text-clr-off-white font-semibold text-xl">
												{homeDetails?.tolCoinBalance}
											</span>
										</p>
										<p className="text-clr-off-white font-semibold text-xl">
											BNB
										</p>
									</div>
								</div>
								{/* btns */}
								<div className="flex sm:flex-row flex-row sm:space-x-6 space-x-0 sm:space-y-0 space-y-0">
									<button className="flex flex-row space-x-4 items-center justify-center border-2 border-clr-main-bright text-clr-main-bright hover:text-clr-main-dark hover:bg-clr-main-bright text-xl font-semibold rounded-full px-10 py-3">
										<span>Withdraw</span>
										<FaArrowUp />
									</button>
									<button className="flex flex-row space-x-4 items-center justify-center border-2 border-clr-main-bright text-clr-main-dark bg-clr-main-bright hover:text-clr-main-bright hover:bg-transparent text-xl font-semibold rounded-full px-10 py-3">
										<span>Deposit</span>
										<FaArrowDown />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WalletDetailsPage;
