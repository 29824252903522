import React, { useEffect, useState } from "react";

// lib
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Pagination } from "antd";

// custom
import { axios_auth } from "../../../utils/axios";
import { api_url } from "../../../utils/constants";
import { FaEdit, FaSearch } from "react-icons/fa";
import { exportExcelFile } from "../../../utils/helpers";

function InDirectRewards() {
	const [homeDetails, setHomeDetails]: any = useState([]);
	const [requestTotal, setRequestsTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [searchVal, setSearchVal] = useState("");
	const [levelVal, setLevelVal] = useState(1);

	const handleChangePage = (page: any, pageSize: any) => {
		setCurrentPage(page);
		setPerPage(pageSize);
	};

	const handleChangeSearch = (e: any) => {
		const val = e.target.value;
		setSearchVal(val);
	};

	useEffect(() => {
		if (levelVal >= 1 && levelVal <= 15 && !Number.isNaN(levelVal)) {
			axios_auth(Cookies.get("token"))
				.get(api_url + "/admin/get-direct-rewards/" + levelVal)
				.then((response: any) => {
					//console.log("key", response.data.data);
					setHomeDetails(response.data.data.data);
					setRequestsTotal(response.data.data.total);
					setCurrentPage(response.data.data.current_page);
				})
				.catch((error) => {
					toast.error(error.message, {
						position: "top-left",
					});
				});
		} else {
			toast.error("The Level Value should be between 1 and 15", {
				position: "top-left",
			});
		}
	}, []);

	useEffect(() => {
		if (levelVal >= 1 && levelVal <= 15 && !Number.isNaN(levelVal)) {
			var url =
				api_url +
				"/admin/get-direct-rewards/" +
				levelVal +
				"?page=" +
				currentPage +
				"&perPage=" +
				perPage;
			if (searchVal.length >= 3) {
				url += "&filter[user.email]=" + searchVal;
			}

			axios_auth(Cookies.get("token"))
				.get(url)
				.then((response: any) => {
					//console.log("key", response.data.data);
					setHomeDetails(response.data.data.data);
					setRequestsTotal(response.data.data.total);
					setCurrentPage(
						response.data.data.current_page > response.data.data.last_page
							? 1
							: response.data.data.current_page
					);
				})
				.catch((error) => {
					toast.error(error.message, {
						position: "top-left",
					});
				});
		} else {
			toast.error("The Level Value should be between 1 and 15", {
				position: "top-left",
			});
		}
	}, [currentPage, searchVal, perPage, levelVal]);

	const handleExport = () => {
		exportExcelFile(homeDetails, "self-rewards");
	};

	return (
		<div className="flex flex-col mx-auto w-full">
			<div className="flex flex-col space-y-3">
				{/* table */}
				<div className="flex flex-col items-start w-full  relative">
					<div className="flex flex-row items-center justify-between relative py-2 w-full space-x-4">
						<div className="flex flex-row space-x-2 items-center">
							<h2 className="text-clr-main-bright text-2xl font-bold font-mono">
								IN-Direct Rewards:
							</h2>
							<div className="flex flex-row items-center relative w-20">
								{/* <label
									htmlFor="search"
									className="text-clr-background absolute right-2"
								>
									<FaSearch className="w-5" />
								</label> */}
								<input
									id="search"
									type="number"
									min={1}
									max={15}
									className="py-2 pl-2 rounded-md text-clr-main-dark w-full"
									placeholder="Level"
									value={levelVal}
									onChange={(e) => setLevelVal(parseInt(e.target.value))}
								/>
							</div>
						</div>

						<div className="flex flex-row space-x-2 items-center">
							<button
								className="bg-clr-main-bright py-2 px-5 rounded-lg text-clr-main-dark font-bold hover:shadow-sm hover:shadow-clr-main-bright"
								onClick={handleExport}
							>
								Export
							</button>
							<div className="flex flex-row items-center relative">
								<label
									htmlFor="search"
									className="text-clr-background absolute right-2"
								>
									<FaSearch className="w-5" />
								</label>
								<input
									id="search"
									type="text"
									className="py-2 px-5 rounded-md text-clr-main-dark"
									placeholder="Search"
									value={searchVal}
									onChange={(e) => handleChangeSearch(e)}
								/>
							</div>
						</div>
					</div>
					{homeDetails.length > 0 && (
						<div className="w-full flex flex-col space-y-3">
							<div className="overflow-hidden border rounded-lg w-full">
								<table className="min-w-full divide-y divide-gray-200 overflow-x-scroll">
									<thead className="bg-clr-secondary-bright">
										<tr className="rounded-t-xl">
											<th className="py-2 px-4 ">Name</th>
											<th className="py-2 px-4 ">Email</th>
											<th className="py-2 px-4 ">Amount</th>
											<th className="py-2 px-4 ">Date</th>
										</tr>
									</thead>
									<tbody className="">
										{homeDetails.length > 0 &&
											homeDetails.map((item: any) => {
												return (
													<tr
														key={item.id}
														className={
															"cursor-pointer hover:bg-clr-gray-light "
														}
													>
														<td className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal">
															{item.user.name}
														</td>
														<td className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal">
															{item.user.email}
														</td>
														<td className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal ">
															{/* {item.direct_parent_name} */}
															{item.amount}
														</td>
														<td className="py-2 px-1  text-center text-clr-gray-dark font-medium border border-clr-gray-normal ">
															{/* {item.child_name} */}
															{item.date}
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>

							<div>
								<Pagination
									className={"custom-pagination "}
									defaultCurrent={currentPage}
									total={requestTotal}
									pageSize={perPage}
									onChange={handleChangePage}
									pageSizeOptions={[10, 20, 50, 100]}
									hideOnSinglePage={true}
									showSizeChanger={true}
								/>
							</div>
						</div>
					)}

					{homeDetails.length <= 0 && (
						<div className="flex flex-col items-center justify-center mx-auto">
							<p className="text-clr-off-white text-4xl py-5 font-semibold">
								There is no Request Today
							</p>
						</div>
					)}
				</div>
				{/* end table */}
			</div>
		</div>
	);
}

export default InDirectRewards;
