import React, { useState, useEffect } from "react";

// lib
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// custom
import Aside from "../../components/Shared/Aside";
import { useAuthContext } from "../../contexts/auth_context";
import { axios_auth } from "../../utils/axios";
import { api_url } from "../../utils/constants";
import TeamCards from "../../components/Client/Teams/TeamCards";
import TeamDetails from "../../components/Client/Teams/TeamDetails";
import TeamLevelDetails from "../../components/Client/Teams/TeamLevelDetails";
import { useMainContext } from "../../contexts/main_context";
import NotAllowed from "../../components/Shared/NotAllowed";
import UserTeamDetails from "../../components/Client/Teams/UserTeamDetails";
import UserLevelDetails from "../../components/Client/Teams/UserLevelDetails";

function TeamPage() {
	const { user } = useAuthContext();
	const { currentSection, SetCurrentsection } = useMainContext();
	const [homeDetails, setHomeDetails]: any = useState([]);
	//const [currentSection, setCurrentSection] = useState(2);

	const handleSection = () => {
		if (currentSection !== 1) {
			SetCurrentsection(currentSection - 1);
			//setCurrentSection(currentSection - 1);
		}
	};

	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.get(api_url + "/team/get-statistics")
			.then((response: any) => {
				//console.log("key", response.data.data);
				setHomeDetails(response.data.data);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, []);

	return (
		<div className="my-10 h-auto  relative overflow-hidden flex lg:items-center items-start lg:justify-center justify-start">
			{/* bolb */}
			<div
				id="bolb"
				className="absolute w-[300px] h-[300px] S-xl:-top-[5px] lg:top-1 -top-25 right-[100px] rounded-full"
			></div>
			{/* end bolb */}
			<div className="mx-auto lg:my-0 my-20 lg:max-w-max-custom max-w-max-width w-90vw">
				<div className="grid grid-cols-12 gap-5">
					{/* aside */}
					<Aside />
					{/* content */}
					<div className="S-950:col-span-9 col-span-12">
						<div className="flex flex-col space-y-6">
							<div className="py-4 border-b border-clr-main-bright flex lg:flex-row lg:space-y-0 flex-col space-y-1 lg:items-center items-start justify-between">
								<p>
									<span>Name: </span> <span>{user.user.name}</span>
								</p>
								<p>
									<span>Email: </span> <span>{user.user.email}</span>
								</p>
								<p>
									<span>Referral code: </span>{" "}
									<span>{user.user.referral_code}</span>
								</p>
							</div>
							{currentSection !== 1 && (
								<div className="flex flex-col items-end justify-end">
									<button
										type="button"
										onClick={handleSection}
										className="px-3 py-1 bg-clr-main-bright text-clr-main-dark hover:text-clr-off-white flex flex-row items-center space-x-2 rounded-full"
									>
										<FaArrowLeft />
										<span className="font-bold">Back</span>
									</button>
								</div>
							)}

							{/* CARDS */}
							{user.user.isAdmin ? (
								<>
									{currentSection === 1 && <TeamCards />}

									{currentSection === 2 && <TeamDetails />}

									{currentSection === 3 && <TeamLevelDetails />}

									{currentSection === 4 && <UserTeamDetails />}

									{currentSection === 5 && <UserLevelDetails />}
								</>
							) : (
								<NotAllowed />
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TeamPage;
