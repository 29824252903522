import React, { useState, useEffect } from "react";

// lib
import { FaArrowRight, FaUser } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// custom
import { useAuthContext } from "../../../contexts/auth_context";
import { axios_auth } from "../../../utils/axios";
import { api_url } from "../../../utils/constants";
import { useMainContext } from "../../../contexts/main_context";

function UserLevelDetails() {
	const { user } = useAuthContext();
	const {
		currentSection,
		SetCurrentsection,
		currentLevel,
		SetCurrentLevel,
		currentUser,
		setCurrentUser,
	} = useMainContext();
	const [homeDetails, setHomeDetails]: any = useState([]);

	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.post(api_url + "/admin/get-level-details/" + currentUser.currentLevel, {
				userId: currentUser.details.child_id,
			})
			.then((response: any) => {
				//console.log("key", response.data.data);
				setHomeDetails(response.data.data);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, []);

	return (
		<div className="flex flex-col mx-auto items-center justify-center">
			<div className="flex flex-row items-start justify-between py-5">
				<h1 className="text-5xl text-clr-secondary-dark font-bold text-start">
					{`${currentUser.details.child_name} -  Level ${currentUser.currentLevel}`}
				</h1>
			</div>

			<div className="flex flex-col space-y-10 items-start justify-start">
				{/* ACTIVE */}
				{homeDetails?.active_users?.length > 0 && (
					<div className="flex flex-col">
						<h2 className="text-2xl py-2 text-clr-main-bright font-semibold">
							Active User:
						</h2>
						<div className="grid md:grid-cols-2 grid-cols-1 gap-x-32 gap-y-5 w-full relative">
							{homeDetails?.active_users.map((item: any) => {
								return (
									<div
										key={item.child_id}
										className="group flex flex-row items-center justify-between space-x-10 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg py-3 px-8 cursor-pointer"
										id="borderTest"
									>
										<div className="flex flex-col items-start">
											<span className="text-xl text-clr-off-white font-bold">
												{`${item.child_name}`}
											</span>
											<p className="flex flex-row space-x-10">
												<span className="text-xl text-clr-main-bright font-semibold flex flex-row space-x-2 items-center">
													<FaUser />
													<span>{item.child_referral_code}</span>
												</span>
												<span className="text-xl text-clr-main-bright font-semibold flex flex-row space-x-2 items-center">
													<GiMoneyStack />
													<span>
														{item.staked_amount !== null
															? parseFloat(item.staked_amount).toFixed(2)
															: 0.0}
													</span>
												</span>
											</p>
										</div>
										{/* <FaArrowRight className="text-clr-main-bright group-hover:bg-clr-main-bright group-hover:text-clr-main-dark rounded-full w-8 h-8 py-2" /> */}
									</div>
								);
							})}
						</div>
					</div>
				)}
				{/* In ACTIVE */}
				{homeDetails?.inactive_users?.length > 0 && (
					<div className="flex flex-col">
						<h2 className="text-2xl py-2 text-clr-main-bright font-semibold">
							In-Active User:
						</h2>
						<div className="grid md:grid-cols-2 grid-cols-1 gap-x-32 gap-y-5 w-full relative">
							{homeDetails?.inactive_users.map((item: any) => {
								return (
									<div
										key={item.child_id}
										className="group flex flex-row items-center justify-between space-x-10 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg py-3 px-8 cursor-pointer"
										id="borderTest"
									>
										<div className="flex flex-col items-start">
											<span className="text-xl text-clr-off-white font-bold">
												{`${item.child_name}`}
											</span>
											<p className="flex flex-row space-x-10">
												<span className="text-xl text-clr-main-bright font-semibold flex flex-row space-x-2 items-center">
													<FaUser />
													<span>{item.child_referral_code}</span>
												</span>
												<span className="text-xl text-clr-main-bright font-semibold flex flex-row space-x-2 items-center">
													<GiMoneyStack />
													<span>
														{item.staked_amount !== null
															? parseFloat(item.staked_amount).toFixed(2)
															: 0.0}
													</span>
												</span>
											</p>
										</div>
										{/* <FaArrowRight className="text-clr-main-bright group-hover:bg-clr-main-bright group-hover:text-clr-main-dark rounded-full w-8 h-8 py-2" /> */}
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default UserLevelDetails;
