import React, { useState, useEffect } from "react";

// lib
import { FaArrowRight } from "react-icons/fa";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// custom
import { useAuthContext } from "../../../contexts/auth_context";
import { axios_auth } from "../../../utils/axios";
import { api_url } from "../../../utils/constants";
import { useMainContext } from "../../../contexts/main_context";

function TeamCards() {
	const { user } = useAuthContext();
	const { currentSection, SetCurrentsection } = useMainContext();
	const [homeDetails, setHomeDetails]: any = useState([]);

	useEffect(() => {
		axios_auth(Cookies.get("token"))
			.get(api_url + "/admin/get-statistics")
			.then((response: any) => {
				//console.log("key", response.data.data);
				setHomeDetails(response.data.data);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	}, []);

	return (
		<div className="flex flex-col mx-auto items-center justify-center">
			<div className="grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-5 w-full relative">
				{/* card */}
				<div
					className="group flex flex-row items-center justify-between space-x-28 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg py-3 px-8 cursor-pointer"
					id="borderTest"
					onClick={() => SetCurrentsection(2)}
				>
					<div className="flex flex-col items-start">
						<span className="text-xl text-clr-off-white font-bold">
							Total Team Members
						</span>
						<span className="text-xl text-clr-main-bright font-semibold">
							{homeDetails?.team_member_count
								? homeDetails?.team_member_count
								: 0}
						</span>
					</div>
					<FaArrowRight className="text-clr-main-bright group-hover:bg-clr-main-bright group-hover:text-clr-main-dark rounded-full w-8 h-8 py-2" />
				</div>
				{/* card */}
				<div
					className="flex flex-row items-center justify-between space-x-28 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg py-3 px-8"
					id="borderTest"
				>
					<div className="flex flex-col items-start">
						<span className="text-xl text-clr-off-white font-bold">
							Active Team Members
						</span>
						<span className="text-xl text-clr-main-bright font-semibold">
							{homeDetails?.active_team_member_count
								? homeDetails?.active_team_member_count
								: 0}
						</span>
					</div>
				</div>
				{/* card */}
				<div
					className="flex flex-row items-center justify-between space-x-28 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg py-3 px-8"
					id="borderTest"
				>
					<div className="flex flex-col items-start">
						<span className="text-xl text-clr-off-white font-bold">
							In-Active Team Members
						</span>
						<span className="text-xl text-clr-main-bright font-semibold">
							{homeDetails?.inactive_team_member_count
								? homeDetails?.inactive_team_member_count
								: 0}
						</span>
					</div>
				</div>
				{/* card */}
				<div
					className="flex flex-row items-center justify-between space-x-28 shadow-btn_shadow backdrop-blur-xl bg-clr-bg-btn-1 rounded-lg py-3 px-8"
					id="borderTest"
				>
					<div className="flex flex-col items-start">
						<span className="text-xl text-clr-off-white font-bold">
							Direct Members
						</span>
						<span className="text-xl text-clr-main-bright font-semibold">
							{homeDetails?.direct_team_member_count
								? homeDetails?.direct_team_member_count
								: 0}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TeamCards;
