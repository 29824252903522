import React, { useState, useEffect } from "react";

// lib
import { Modal } from "antd";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// custom
import { axios_auth } from "../../../utils/axios";
import { api_url } from "../../../utils/constants";
import { useMainContext } from "../../../contexts/main_context";
import { classNames } from "../../../utils/helpers";

// regex
const key_REGEX = /^[a-zA-Z0-9]{64,66}$/;

function ConfirmWallet() {
	const {
		isPrivateModalOpen,
		closePrivateModal,
		currentWithdrawal,
		closeRequestModal,
	} = useMainContext();
	const [approveLoading, setApproveLoading] = useState(false);

	const [pKey, setPKey] = useState("");
	const [pKeyValid, setPKeyValid] = useState(false);
	const [pKeyFocus, setPKeyfocus] = useState(false);

	const handleApprove = async () => {
		if (pKeyValid) {
			//console.log("pKeyValid", pKeyValid);
			setApproveLoading(true);
			axios_auth(Cookies.get("token"))
				.post(
					api_url + "/admin/withdrawals/" + currentWithdrawal.id + "/approve",
					{ privateKey: pKey }
				)
				.then((response: any) => {
					//console.log("key", response.data.data);
					setApproveLoading(false);
					closeRequestModal();
					closePrivateModal();
					if (response.data.status) {
						toast.success(response.data.message, {
							position: "top-left",
						});
					} else {
						toast.error(response.data.message, {
							position: "top-left",
						});
						//window.location.reload();
					}
				})
				.catch((error: any) => {
					toast.error(error.message, {
						position: "top-left",
					});
					setApproveLoading(false);
				});
		} else {
			toast.error("Please fill in the private key field", {
				position: "top-left",
			});
		}
	};

	//check the validation for private key
	useEffect(() => {
		setPKeyValid(key_REGEX.test(pKey));
	}, [pKey]);

	//set private key
	const handlePassword = (e: any) => {
		const val = e.currentTarget.value;
		setPKey(val);
	};

	return (
		<Modal
			title={
				<div className="flex flex-row space-x-1 items-center bg-clr-secondary-bright">
					<h1 className="text-3xl font-bold text-clr-dark-purple bg-transparent">
						Complete Withdrawal Proccess
					</h1>
				</div>
			}
			style={{ top: 20 }}
			footer={<div></div>}
			open={isPrivateModalOpen}
			onOk={closePrivateModal}
			onCancel={closePrivateModal}
		>
			{/* private */}
			<div className="flex flex-col space-y-8 py-5">
				<div className="flex flex-col items-start justify-start w-full space-y-1">
					<h2 className="text-2xl font-semibold text-clr-off-white ">
						Private key
					</h2>
					<input
						id="p_key"
						name="p_key"
						type={"password"}
						autoComplete="off"
						aria-invalid={pKeyValid ? "false" : "true"}
						aria-describedby="uidnote"
						onFocus={() => setPKeyfocus(true)}
						onBlur={() => setPKeyfocus(false)}
						value={pKey}
						onChange={(e) => handlePassword(e)}
						className={classNames(
							"px-4 py-2 rounded-xl bg-transparent w-full font-bold text-clr-gray-dark text-base outline-none",
							pKeyValid
								? "border-2 border-clr-secondary-dark"
								: "border border-clr-main-dark",
							!pKeyValid && pKeyFocus
								? "border-2 border-red-600"
								: "border border-clr-main-dark"
						)}
					/>
				</div>
			</div>

			<div className="flex flex-row space-x-5 items-center justify-center mt-14">
				<button
					onClick={handleApprove}
					className="px-4 py-1 rounded-md shadow-bene_shadow shadow-black text-lg font-semibold bg-clr-main-dark text-clr-main-bright border-2 border-clr-main-dark hover:bg-transparent hover:text-clr-main-dark"
				>
					Transfer
					{approveLoading && (
						<svg
							aria-hidden="true"
							role="status"
							className="inline w-4 h-4 ml-3 text-white font-semibold animate-spin"
							viewBox="0 0 100 101"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								fill="#E5E7EB"
							/>
							<path
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								fill="currentColor"
							/>
						</svg>
					)}
				</button>
			</div>
		</Modal>
	);
}

export default ConfirmWallet;
