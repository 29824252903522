import React, { useEffect, useState } from "react";

// lib
import { toast } from "react-toastify";

// custom
import { api_url } from "../../../utils/constants";
import { axios, axios_auth } from "../../../utils/axios";
import SinglePlan from "./SinglePlan";
import { useMainContext } from "../../../contexts/main_context";

function PlansList() {
	const { selectedPlan, setSelectedPlan } = useMainContext();
	const [plansList, setPlansList] = useState([]);
	const [currentPlan, setCurrentPlan] = useState(1);

	const getPlans = async () => {
		axios
			.get(api_url + "/packages")
			.then((response: any) => {
				setPlansList(response.data.data);
			})
			.catch((error) => {
				toast.error(error.message, {
					position: "top-left",
				});
			});
	};

	useEffect(() => {
		getPlans();
	}, []);

	return (
		<div className="mx-auto my-20 max-w-max-width w-90vw">
			<div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10">
				{plansList.length > 0 &&
					plansList
						.sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
						.map((item: any) => {
							return (
								<SinglePlan
									key={item.id}
									data={item}
									choosed={setCurrentPlan}
								/>
							);
						})}
			</div>
		</div>
	);
}

export default PlansList;
